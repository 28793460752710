// Generated by Framer (46f1e3d)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Carousel from "https://framerusercontent.com/modules/UIrMjSS6ZX89L0CsT8k6/w90zR0qzeh1mgaDSvD54/Carousel.js";
import * as localizedValues from "./c85lbDrzC-0.js";
const CarouselFonts = getFonts(Carousel);

const cycleOrder = ["NFBbp1KUP"];

const serializationHash = "framer-HYPEM"

const variantClassNames = {NFBbp1KUP: "framer-v-1m2v1bl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "NFBbp1KUP", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1m2v1bl", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"NFBbp1KUP"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgba(153, 238, 255, 0)", ...style}}><ComponentViewportProvider ><motion.div className={"framer-9xp22n-container"} layoutDependency={layoutDependency} layoutId={"mAQjQ6ZGk-container"}><Carousel align={"flex-start"} ariaLabel={""} arrowObject={{arrowFill: "rgba(0, 0, 0, 0.2)", arrowPadding: 20, arrowRadius: 40, arrowSize: 40, showMouseControls: true}} axis borderRadius={0} fadeObject={{fadeAlpha: 0, fadeContent: false, fadeInset: 0, fadeTransition: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}, fadeWidth: 25}} gap={30} height={"100%"} id={"mAQjQ6ZGk"} layoutId={"mAQjQ6ZGk"} padding={0} paddingBottom={0} paddingLeft={0} paddingPerSide={false} paddingRight={0} paddingTop={0} progressObject={{dotsActiveOpacity: 1, dotsBackground: "rgba(0, 0, 0, 0.2)", dotsBlur: 4, dotsFill: "rgb(255, 255, 255)", dotsGap: 10, dotsInset: 10, dotSize: 10, dotsOpacity: 0.5, dotsPadding: 10, dotsRadius: 50, showProgressDots: false, showScrollbar: false}} sizingObject={{heightInset: 0, heightRows: 2, heightType: "auto", widthColumns: 2, widthInset: 0, widthType: "auto"}} slots={[]} snapObject={{fluid: false, snap: true, snapEdge: "center"}} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-HYPEM.framer-s9ifcb, .framer-HYPEM .framer-s9ifcb { display: block; }", ".framer-HYPEM.framer-1m2v1bl { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: auto; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 1100px; }", ".framer-HYPEM .framer-9xp22n-container { flex: 1 0 0px; height: 466px; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-HYPEM.framer-1m2v1bl { gap: 0px; } .framer-HYPEM.framer-1m2v1bl > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-HYPEM.framer-1m2v1bl > :first-child { margin-left: 0px; } .framer-HYPEM.framer-1m2v1bl > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 466
 * @framerIntrinsicWidth 1100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerc85lbDrzC: React.ComponentType<Props> = withCSS(Component, css, "framer-HYPEM") as typeof Component;
export default Framerc85lbDrzC;

Framerc85lbDrzC.displayName = "carousel-news-mobile";

Framerc85lbDrzC.defaultProps = {height: 466, width: 1100};

addFonts(Framerc85lbDrzC, [{explicitInter: true, fonts: []}, ...CarouselFonts], {supportsExplicitInterCodegen: true})